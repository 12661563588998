<template>
    <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
    <div v-else class="input-wrapper" :class="[{ 'error' : error }]">
        <div class="input-container" :id="computedid" @click="openOptions" v-click-outside="closeOptions">
            <span class="selected-option" v-for="option in value" :key="option.displayid" @click="removeOption(option)">{{ option.displayname }} <v-icon name="times"/></span>
            
            <ul class="optionlist" v-show="optionsopen">
                <li v-for="option in availableoptions" :key="option.displayid" @click="selectOption(option)">{{ option.displayname }}</li>
            </ul>
        </div>

        <slot v-if="!display" ></slot>

        <label class="display" v-else>{{ value }}</label>

        <label :for="computedid" class="input-label" :class="{ 'float' : this.selected.length > 0 }" v-if="label" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''">{{ this.label }} {{ required ? '*' : '' }}</label>

        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errortext }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MultiSelectInput',
    props: {
        value: {
            type: Array,
            default: () => { return [] }
        },
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: ' ' // is used to trigger the floating label so it can not be empty
        },
        display: Boolean,
        isloading: Boolean,
        disabled: Boolean,
        label: String,
        required: Boolean,
        error: Boolean,
        errortext: String,
        backgroundcolor: String,
        min: Number,
        inputid: String,
        idstub: String
    },
    data() {
        return {
            selected: this.value,
            optionsopen: false
        }
    },
    watch: {
        selected(newval) {
            this.$emit('input', newval)
        }
    },
    computed: {
        computedid: function() {
            return this.inputid || `input-${this._rng(5)}${this.idstub ? '-' + this.idstub : ''}`
        },
        availableoptions() {
            return this.options.filter(option => {
                return -1 === this.selected.findIndex(item => item.displayid === option.displayid)
            })
        }
    },
    methods: {
        openOptions() {
            this.optionsopen = true
        },
        closeOptions() {
            this.optionsopen = false
        },
        selectOption(option) {
            this.optionsopen = false
            this.selected.push(option)
        },
        removeOption(option) {
            const itemidx = this.selected.findIndex(item => item.displayid === option.displayid)
            this.selected.splice(itemidx, 1)
        }
    }
}
</script>

<style scoped>
.input-wrapper {
    position: relative;
}

.input-wrapper {
    padding-top: 15px;
}

.input-wrapper > .input-label {
    position: absolute;
    font-size: 1em;
    left: 10px;
    background: #fff;
    top: 6px;
    padding: 0px 5px;
    transition: .1s all;
    height: 10px;
    line-height: 10px;
}

.input-label.float {
    top: 0px;
    left: 0px;
}

.input-wrapper > .display {
    margin-top: 5px;
    display: block;
    white-space: pre-wrap;
    text-align: left;
    margin-left: 20px;
}

.display + .input-label {
    font-weight: bold;
    font-size: 1.1em;
}

.input-container {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    font-size: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: #fff;
    border-radius: var(--input-border-radius);
    padding-left: 10px;
    border: 1px solid #064A6C;
}

.input-container:hover {
    cursor: pointer;
}

.textinput-container > label {
    color: #fff;
}

.input-label {
    font-size: 1.1em;
    color: #2c2e2f;
}

.error-container {
    min-height: 15px;
    margin-top: 3px;
    padding-left: 10px;
    color: red;
    text-align: left;
}

.error-container > .error-label {
    visibility: hidden;
}

.error-container > .error-label.show {
    visibility: visible;
}

.input-wrapper.error >>> * {
    color: red;
}

.input-wrapper.error > .input-container {
    border: 1px solid red;
}

.input-container > ul {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    position: absolute;
    top: 28px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    box-shadow: 0px 1px 10px rgb(100 100 150 / 30%);
    z-index: 10;
}

.input-container > ul > li {
    text-align: left;
    text-decoration: none;
    padding: 10px 0;
    padding-left: 10px;
    user-select: none;
}

.input-container > ul > li:hover {
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.input-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
}

.input-container > .selected-option {
    background-color: #064A6C;
    color: #fff;
    fill: #fff;
    height: fit-content;
    border-radius: 8px;
    padding: 0 10px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    flex-basis: auto;
    user-select: none;
}

.input-container > .selected-option:hover {
    background-color: #064A6Ccc;
    cursor: pointer;
}

.input-container > .selected-option > svg {
    margin-left: 8px;
    padding: 1px;
}
</style>