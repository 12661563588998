import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

const availablelocales = locales.keys().map(entry => entry.substr(2, 2).toLowerCase())

function loadLocaleMessages (clientLocales) {
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            // date picker locale, import in accordance to selected locale
            // require(`vue2-datepicker/locale/${locale}`)
            messages[locale] = locales(key)
        }
    })

    // Kunden-Sprachdatei überschreibt die Sprach-Einträge im messages-Array(z.B. de wird von de_VVSFlisl.json überschrieben)
    if(clientLocales) {
        clientLocales.forEach(locale => {
            let localeKey = Object.keys(locale)[0]

            if(messages[localeKey]) {
                for (const key in messages[localeKey]) {
                    if(Object.values(locale)[0][key]) {
                        messages[localeKey][key] = MergeRecursive(messages[localeKey][key], Object.values(locale)[0][key])
                    }
                }
            }
        })
    }

    return messages
}

// https://stackoverflow.com/questions/171251/how-can-i-merge-properties-of-two-javascript-objects-dynamically
// Recursively merge properties of two objects
function MergeRecursive(obj1, obj2) {
    for (var p in obj2) {
        try {
            // Property in destination object set update its value.
            if ( obj2[p].constructor==Object ) {
                obj1[p] = MergeRecursive(obj1[p], obj2[p])
            } else {
                obj1[p] = obj2[p]
            }
        } catch(e) {
            // Property in destination object not set create it and set its value.
            obj1[p] = obj2[p]
        }
    }

    return obj1
}

function getDefaultLocale() {
    let locale = process.env.VUE_APP_I18N_LOCALE || 'de'

    const browserlocale = navigator.language.substring(0, 2).toLowerCase()

    if (availablelocales.includes(browserlocale)) {
        locale = browserlocale
    }

    return locale
}

export function createI18n(clientLocales) {
    const i18n = new VueI18n({
        locale: getDefaultLocale(),
        // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
        messages: loadLocaleMessages(clientLocales)
    })

    i18n.availablelocales = availablelocales

    return i18n
}
