import { render, staticRenderFns } from "./CompleteOrderPage.vue?vue&type=template&id=7537fe10&scoped=true"
import script from "./CompleteOrderPage.vue?vue&type=script&lang=js"
export * from "./CompleteOrderPage.vue?vue&type=script&lang=js"
import style0 from "./CompleteOrderPage.vue?vue&type=style&index=0&id=7537fe10&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7537fe10",
  null
  
)

export default component.exports