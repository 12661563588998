<template>
    <div class="header-container">
        <img class="client-logo" :class="{ 'with-corporation-logo': withCorporationLogo, }" :src="logouri"/>
        <img v-if="corporationLogouri" class="corporation-logo" :src="corporationLogouri"/>
    </div>
</template>

<script>
export default {
    name: 'Header',
    computed: {
        logouri() {
            return this.$root.$data.systemconfiguration.clientlogo
        },
        corporationLogouri() {
            return this.$root.$data.systemconfiguration.corporationlogo
        },
        withCorporationLogo() {
            return this.$root.$data.systemconfiguration.corporationlogo? true : false
        }
    },
}
</script>

<style scoped>
.header-container {
    width: 100%;
    height: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 3px rgb(100 100 150 / 10%);
}

.header-container > .client-logo {
    margin-left: 10px;
    height: auto;
    max-height: 40px;
    width: 180px;
}

.header-container > .with-corporation-logo {
    max-width: 45%;
}

.header-container > .corporation-logo {
    margin-right: 10px;
    margin-left: 10px;
    height: auto;
    max-height: 40px;
    width: 180px;
    max-width: 45%;
}
</style>