<template>
    <div class="upload-container round-container">
        <div class="upload-wrapper" :class="{ 'hovering' : hovering }" @click="$refs.file.click()">
            <div class="upload-area" @dragover="dragOver" @dragleave="dragLeave" @drop="dropInto">
                <input :id="inputid" type="file" multiple @change="onChange" ref="file" :accept="acceptedFileTypes" hidden/>
                <div class="label-container">
                    <label>{{ uploadtext }}</label>
                </div>
            </div>
        </div>
        <div class="error-container round-container" v-if="typeerror || error">
            <label>{{ error || 'Falsches Dateiformat!' }}</label>
        </div>
    </div>
</template>

<script>
const ANY_FILE = '*'
const EMIT_UPLOAD = 'upload'
const EMIT_INVALID_TYPE = 'invalidType'
const EMIT_LIMIT = 'uploadLimit'

export default {
    name: 'DragAndDropUpload',
    emits: [EMIT_UPLOAD, EMIT_INVALID_TYPE, EMIT_LIMIT],
    props: {
        inputid: String,
        uploadtext: {
            type: String,
            default: 'Hello World'
        },
        acceptedFileTypes: {
            type: String,
            default: ANY_FILE,
            example: 'image/png, image, application/pdf, pdf' // comma seperated list of full or partial mime types
            // list of all broeser accepted mime-types:
            // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
        },
        uploadlimit: {
            type: Number,
            default: 0
        },
        error: {
            type: String,
            default: undefined
        }
    },
    data() {
        return {
            hovering: false,
            typeerror: false,
            counterror: false
        }
    },
    methods: {
        dragOver(event) {
            event.preventDefault()
            this.hovering = true
        },
        dragLeave() {
            this.hovering = false
        },
        dropInto(event) {
            event.preventDefault();
            this.dragLeave()
            this.newFiles(event.dataTransfer.files)
        },
        onChange() {
            this.newFiles(this.$refs.file.files)
        },
        newFiles(filelist) {
            this.typeerror = false
            
            this.$emit(EMIT_UPLOAD, [])

            if (this.uploadlimit !== 0 && filelist.length > this.uploadlimit) {
                this.$emit(EMIT_LIMIT)
            } else if (this.isAcceptedType(filelist)) {
                this.$emit(EMIT_UPLOAD, filelist)
            } else {
                this.typeerror = true
                this.$emit(EMIT_INVALID_TYPE)
            }
        },
        isAcceptedType(filelist) {
            let filesacceptable = true
            const acceptedtypes = this.acceptedFileTypes
                                    .replaceAll(' ', '')
                                    .split(',')

            if (this.acceptedFileTypes !== ANY_FILE) {
                Object.keys(filelist).forEach(fileindex => {
                    let fileAcceptable = false
                    acceptedtypes.forEach(type => {
                        if (filelist[fileindex].type.match(type)) {
                            fileAcceptable = true
                        }
                    })
                    if(!fileAcceptable) {
                        filesacceptable = false
                    }
                })
            }

            return filesacceptable
        }
    }
}
</script>

<style scoped>
.upload-container {
    width: 92.5%;
    margin: 0 auto;
    min-height: 125px;
    background: #fff;
    overflow: hidden;
}

.upload-container > .upload-wrapper {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.upload-container > .upload-wrapper:hover,
.upload-container > .upload-wrapper:hover >>> * {
    cursor: pointer;
}

.upload-container > .upload-wrapper:hover,
.upload-container > .upload-wrapper.hovering {
    background: #ddffdd;
}

.upload-wrapper > .upload-area {
    height: 100%;
    border: 2px dashed #333;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-area > .label-container {
    font-size: 1.2em;
    margin: 10px;
}

.error-container {
    background-color: #fff;
    margin-top: 5px;
    color: red;
    padding: 5px;
}
</style>