import Vue from 'vue'
import App from '@/App.vue'
import env from '@/plugins/util'
import { createI18n } from '@/plugins/i18n'
import Icon from 'vue-awesome'
import axios from 'axios'
import toast from './plugins/toast'
import mixins from '@/plugins/mixin'
import router from '@/plugins/router'
import vuelidate from '@/plugins/vuelidate'
import clickoutside from './plugins/clickoutside'

Vue.config.productionTip = false

Vue.component('v-icon', Icon)

let SESSION = {
    id: undefined,
    client: undefined,
    clientid: undefined,
    corporationid: undefined,
    created: undefined,
    refreshed: undefined,
    refreshing: false,
    available: false
}

let SYSTEMCONFIGURATION = {
    configuration: undefined,
    legallinklist: undefined,
    linklist: undefined,
    startpoints: undefined,
    anlageassistent: undefined,
    clientlogo: undefined,
    corporationlogo: undefined,
    fields: undefined
}

let i18n = undefined

// configure axios
axios.defaults.timeout = 60000 // default timeout
const HEADER_HQ_TOKEN = 'hq-token'
axios.interceptors.request.use(config => {
    // set sessionid as header in every request
    config.headers[HEADER_HQ_TOKEN] = SESSION.id
    return config
})

// function calls iteself again after given timeout
const getSession = async (timeout, session, config, i18n) => {
    session.refreshing = true

    let client = (new URL(window.location)).searchParams.get('Mandant') || (new URL(window.location)).searchParams.get('mandant') || (new URL(window.location)).searchParams.get('client')
    let ktnr   = (new URL(window.location)).searchParams.get('KTNR')    || (new URL(window.location)).searchParams.get('ktnr')    || (new URL(window.location)).searchParams.get('corporation')

    if (!client) {
        return Promise.reject('no-client')
    }

    if (!ktnr) {
        console.log('no-corporation')
    }

    return axios.get(Vue.middleware() + '/session' + `?client=${client}&corporation=${ktnr}`)
    .then(response => {
        const data = response.data
        const status = data.status
        const sessiondata = data.data

        if (!sessiondata || status.ErrorCode !== 0) {
            throw status.ErrorMsg
        }

        session.id            = sessiondata.sessionid
        session.client        = sessiondata.client
        session.clientid      = sessiondata.clientid
        session.corporationid = sessiondata.corporationid
        session.refreshed     = new Date()
        session.refreshing    = false

        // start the refresh cycle
        setTimeout(getSession, timeout, timeout, session, config, i18n)

        // return session result once
        return { session, config }
    })
}

// get a new session every 10 minutes (600000ms)
const timeout = 600000
getSession(timeout, SESSION, SYSTEMCONFIGURATION, i18n)
.then(result => {
    let session = result.session
    let config = result.config
    let i18n = result.i18n

    return axios.get(Vue.middleware() + `/clients/${session.clientid}/systemconfiguration`)
    .then(response => {
        const body = response.data

        if (body.status.ErrorCode !== 0) {
            throw body.statuts.ErrorMsg
        }

        config.configuration   = body.data.configuration
        config.legallinklist   = body.data.legallinklist
        config.linklist        = body.data.linklist
        config.startpoints     = body.data.startpoints
        config.anlageassistent = body.data.anlageassistent

        session.available = true
        session.created = new Date()

        return { session, config, i18n }
    })
})
.then(result => {
    let session = result.session
    let config = result.config
    let i18n = result.i18n

    return axios.get(Vue.middleware() + `/clients/${session.clientid}/corporations/${session.corporationid}`)
    .then(response => {

        const body = response.data

        if (body.status.ErrorCode !== 0) {
            throw body.statuts.ErrorMsg
        }

        if (body.data.fieldconfig) {
            try {
                config.fields = JSON.parse(body.data.fieldconfig)
            } catch (err) {
                console.log('Could not parse field config')
            }
        } else {
            console.log('no-fields')
        }

        return { session, config, i18n }
    })
})
.then(result => {
    let session = result.session
    let config = result.config
    let i18n = result.i18n

    return axios.get(Vue.middleware() + `/clients/${session.clientid}/logo`)
    .then(response => {
        const body = response.data

        if (body.status.ErrorCode !== 0) {
            console.log(body.status.ErrorMsg)
        } else {
            config.clientlogo = body.data.logo
        }

        return { session, config, i18n }
    })
})
.then(result => {
    let session = result.session
    let config = result.config
    let i18n = result.i18n

    return axios.get(Vue.middleware() + `/clients/${session.clientid}/corporations/${session.corporationid}/logo`)
    .then(response => {
        const body = response.data

        if (body.status.ErrorCode !== 0) {
            //console.log(body.status.ErrorMsg)
        } else {
            config.corporationlogo = body.data.logo
        }

        return { session, config, i18n }
    })
})
//get locales(i18n) and overrides if they are defined in the middleware
.then(result => {

    let session = result.session
    let config = result.config
    let i18n = result.i18n

    return axios.get(Vue.middleware() + `/locales/anlageassistent/${session.client}`)
    .then(response => {
        const data = response.data

        i18n = createI18n(data)

        return { session, config, i18n }
    })
})
.catch(err => {
    if(err && err === 'no-client') {
        throw err
    } else {
        console.log(err)
    }
})

new Vue({
    data: {
        landed: false,
        session: SESSION,
        systemconfiguration: SYSTEMCONFIGURATION,
        loading: false,
        availableproducts: undefined,
        product: undefined,
        relation: undefined,
        customer: undefined,
        customerHasData: undefined,
        cardowner: undefined,
        bankaccountowner: undefined,
        croppedpicture: undefined,
        isexistingcustomer: false,
        withcorpselection: false,
        successurl: undefined,
        basedata: {
            salutations: [],
            titles: [],
            countries: []
        },
        reset: function() {
            this.landed = false
            this.loading = false
            this.availableproducts = undefined
            this.product = undefined
            this.relation = undefined
            this.customer = undefined
            this.customerHasData = undefined,
            this.cardowner = undefined
            this.bankaccountowner = undefined
            this.croppedpicture = undefined
            this.isexistingcustomer = false
            this.withcorpselection = false
            this.successurl = undefined
            this.basedata = {
                salutations: [],
                titles: [],
                countries: []
            }
        }
    },
    clickoutside,
    mixins,
    env,
    i18n: createI18n(null), //get i18n locales first without clientlocales
    vuelidate,
    router,
    toast,
    render: h => h(App)
}).$mount('#app')